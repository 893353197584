import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import firebaseConfig from './firebaseConfig';

if (!firebase.apps.length) { firebase.initializeApp(firebaseConfig); firebase.analytics(); }

const codeInput = document.querySelector('#formCode');
const codeButton = document.querySelector('#gotoForm');
const fehlermeldungCodeInput = document.querySelector('#fehlermeldungCodeInput');

const cleanCode = input => input.replace(/[^\d\w]/, '');

// clean text while input
codeInput.addEventListener('input', () => {
  fehlermeldungCodeInput.classList.add('hide');
  const cleanInput = cleanCode(codeInput.value);
  codeInput.value = cleanInput;
});

// click submit if enter pressed
codeInput.addEventListener('keyup', ({ key }) => {
  if (key === 'Enter') codeButton.click();
});

// check id on button pressed
codeButton.addEventListener('click', () => {
  const input = cleanCode(codeInput.value);
  idEntered(input);
});

function idEntered(id) {
  if (id.length == 6) {
    window.location.assign(`/${id}`);
  } else if (id.length == 0) {
    fehlermeldungCodeInput.textContent = 'Bitte gib deinen Code ein.';
    fehlermeldungCodeInput.classList.remove('hide');
  } else {
    fehlermeldungCodeInput.textContent = 'Codes sind immer 6 Zeichen lang!';
    fehlermeldungCodeInput.classList.remove('hide');
  }
}

document.querySelector('#demoForm').addEventListener('submit', () => {
  firebase.analytics().logEvent('demozugang');
});
